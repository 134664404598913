.text {
  margin: 20px auto;
  width: 60%;
}

.text p{
  font-style: italic;
  text-align: center;
}

.bar {
  height: 5px;
  margin: 0 auto;
  width: 60%;
  border-radius: 2px;
  background-color: var(--colour);
}