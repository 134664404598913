.section {
	display: flex;
	padding: 30px;
/*	align-items: center;*/
}

.image {
	width: 30vw;
	object-fit: cover;
	max-height: 15vw;
}

.content {
	padding: 0 50px;
	width: 70vw;
}

.content h2{
	margin: 0;
}

.links {
	display: flex;
	justify-content: space-evenly;
}

.section:nth-of-type(2n) {
	flex-direction: row-reverse;
}