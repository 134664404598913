.wrapper{
	display: flex; 
	justify-content: center;
	margin-top: 2%;
}

.sidebar{
	width: 250px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 15px;
}

.image {
	max-width: 200px;
}

.link {
	margin: 5px 20px;
	text-align: center;
	text-decoration: none;
}

.blurb{
	padding-right: 5%;
	max-width: 600px;
}