.wrapper {
  background-color: rgba(0,0,0,0.4);
  height: 100%; 
  left: 0;
  overflow: auto;
  padding-top: 100px; 
  position: fixed; 
  top: 0;
  width: 100%; 
}

.content {
  background-color: #fefefe;
  border-radius: 8px;
  box-shadow: 0 0 4px black;
  display: flex;
  height: 70%;
  margin: auto;
  padding: 20px;
  width: 80%;
}

.image {
  margin-right: 20px;
  object-fit: contain;
  width: 40%;
}

.text {
  overflow: auto;
  padding: 0 30px 30px 10px;
}

.title {
  color: black;
}

.title:hover {
  color: var(--colour);
}

.close {
  background: transparent;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  position: absolute;
  right: 10%;
}

.close:hover {
  background-color: var(--colour);
  color: white;
}
