.top {
	display: flex;
}

.left{
	width: 25vw;
}
.skips{
	position: sticky;
	top: 35%;
	padding-left: 30%;
	list-style: inside;
}

.skip{
	margin: 10px;
}
.skip:hover{
	font-weight: bold;
}

.content{
	display: flex;
	flex-direction: column;
	width: 50vw;
}

.banner{
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: 50% 25%;
	border-top: none;
	border-radius: 0 0 2px 2px;
}

.title{
	margin-bottom:0;
}

.byline{
	margin-top: 0;
	font-style: italic;
	font-size: 12px;
}

.figure{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
}

.figureObject{
  border: 2px solid var(--colour);
  border-radius: 2px;
  box-sizing: border-box;
  margin: 5px;
	max-width: 100%;
	max-height: 50vh;
}

.figureText{
  font-size: 10px;
  padding: 5px;
}

.figureCaption{
	font-style: italic;
	font-size:14px;
}

.figMulti {
	display: flex;
	gap: 2%;
	justify-content: space-between;
}

.figDouble * {
	width: 49%;
}

.figTriple * {
	width: 33%;
}

.drawerBody{
	overflow: scroll;
	border: 2px solid var(--colour);
	border-radius: 0 2px 2px 2px;
	max-height: 50vh;
/*	margin: 0 40px;*/
}

.buttonExpanded {
    border-radius: 2px 2px 0 0 !important;
}

.drawerHeader{
	display: flex;
	align-items: center;
	gap: 10px;
}

.drawerTitle{
	margin:0;
}

.drawerTable {
	width: 100%;
	font-size: 10px;
	white-space:nowrap;
	border-collapse: collapse;
}

.drawerTable tbody tr:hover{
	background-color: var(--colour);
}

.drawerTable th, td{
	padding: 5px;
}

.drawerTable td{
	border-left: 1px solid #00000010;
}

.drawerTable td:first-child{
	border-left: none;
}

.codeModule{
	height: 255px;
	width: 50vw;
}

.textLogo + *{
	clear: both;
}

.logo{
	float: left;
	border-radius: 10%;
	border: none;
	width: 150px;
	height: 150px;
	margin-right: 20px;
}

.refList{
	list-style: none;
	padding: 0;
}

.related{
	padding: 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
}
