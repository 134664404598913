@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Raleway:wght@300;400;500;600&display=swap');

:root {
  --portfolio: #ff595e;
/*  --yellow: #ffca3a;*/
  --archive: #8ac926;
  --about: #1982c4;
  --colour: black;
  scroll-behavior: smooth;
}

.portfolio {
  background-color: var(--portfolio);
  color: white;
}

.archive {
  background-color: var(--archive);
  color: white;
}

.about {
  background-color: var(--about);
  color: white;
}

#root {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  font-family: 'Lora', serif;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
}

main {
  padding-bottom: 80px;
}

h2{
    border-bottom: 2px solid var(--colour);
}

a{
  text-decoration: none;
  color: var(--colour);  
}

p{
  text-align: justify;
}
img{
  border: 2px solid var(--colour);
  border-radius: 2px;
  box-sizing: border-box;
}

.button{
  background-color: var(--colour);
  border-radius: 2px;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-style: none;
  margin-right: 5px;
  font-size: 16px;
}